import {action, configure, makeObservable, observable} from 'mobx';
import {Inertia} from "@inertiajs/inertia";

export default class PasswordRecoveryStore {
    @observable token = '';
    @observable email = '';
    @observable serverErrorFind = '';
    @observable passwordRecoveryServerMessage = '';
    @observable serverErrorCreate = '';
    // Лоадеры
    @observable formLoadFind = '';
    @observable formLoadPhoneCode = '';
    // MTS
    @observable serverErrorMts = '';
    @observable phoneCode = null;
    @observable phoneCodeValues = '';
    @observable phoneCodeFlag = false;
    @observable phoneCodeError = '';
    @observable mtsData = [];
    @observable mtsTimerFlag = '1';
    @observable timerSeconds = '';
    @observable endDate = '';

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        });
        this.rootStore = rootStore;
    }

    /**
     * Сброс пароля
     * @param values - пароль, email
     * @returns {Promise<unknown>}
     */
    @action passwordReset = (value) => {
        try {
            this.formLoadFind = 'load';
            axios.post('/password/reset', value).then((response) => {
                console.log('response', response);
                this.formLoadFind = '';
                const {success} = response.data;
                console.log('success', success);
                if (success.status) {
                    // this.token = response.data.token;
                    // this.email = response.data.email;
                    this.passwordRecoveryServerMessage = success.message;
                } else {
                    this.passwordRecoveryServerMessage = success.message;
                }
            });
        } catch (e) {
            this.formLoadFind = '';
            this.passwordRecoveryServerMessage = '';
            console.log('e', e);
        }
    };

    /**
     * Отправка запроса на сброс пароля по телефону через API MTS
     * @param values
     * @returns {Promise<unknown>|boolean}
     */
    @action resetPasswordByPhone = (values) => {
        try {
            axios.post('/password/reset-password-by-phone', values).then((response) => {
                this.formLoadFind = '';
                console.log('response', response);
                const {success} = response.data;
                // if (!response) {
                //     this.serverErrorMts = response.success.msg;
                //     return false;
                // }

                if (success.status) {
                    this.mtsData = success.data;
                    this.phoneCodeFlag = true;
                    if (this.mtsData.length !== 0 && this.mtsData.numberFull !== '') {
                        localStorage.setItem('mtsTimeStamp', this.mtsData.timeStamp);
                        localStorage.setItem('mtsTimerFlag', '0');
                        this.mtsTimerFlag = false;
                        this.timerMts();
                    }
                } else {
                    this.serverErrorMts = success.msg;
                }
            });
        } catch (e) {
            this.formLoadFind = '';
            console.log('e', e);
        }
    }

    /**
     * Таймер обратного отсчета
     * @param params
     */
    @action timerMts = () => {
        try {
            this.endDate = localStorage.getItem('mtsTimeStamp');
            this.mtsTimerFlag = localStorage.getItem('mtsTimerFlag');
            if (this.endDate === '') {
                this.mtsTimerFlag = '1';
                return false;
            }

            const timer = setInterval(function (data) {
                let difference = new Date(data.endDate) - new Date();

                if (difference <= 0) {
                    data.mtsTimerFlag = '1';
                    data.endDate = '';
                    // Очищаем таймер
                    clearInterval(timer);
                } else {
                    let res = new Date(difference);
                    data.timerSeconds = res.getUTCSeconds();
                }
            }, 1000, this);
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Сравниваем смс код
     * @param e
     * @returns {boolean}
     */
    @action phoneCodeChange = (e) => {
        try {
            this.phoneCodeValues = e.target.value;

            // Удаляем все кроме цифр
            this.phoneCodeValues = this.phoneCodeValues.toString().replace(/[^0-9]/g, '');

            // Если не пришли данные с сервера
            if (this.mtsData === null) {
                return false;
            }

            // Получаем код для сравнения
            const phoneCode = this.mtsData.numberFull.toString().substr(9, 4);

            // Как только ввели нужное количество символов, начинаем проверку
            if (this.phoneCodeValues.toString().length === 4) {
                this.formLoadPhoneCode = 'load';

                if (Number(this.phoneCodeValues) === Number(phoneCode)) {
                    this.formLoadPhoneCode = '';
                    this.phoneCodeError = '';
                    return Inertia.visit(`/web-password-recovery/${this.mtsData.token}`);
                } else {
                    this.phoneCodeError = 'Не верный код подтверждения!';
                    this.formLoadPhoneCode = '';
                }
            }
        } catch (e) {
            this.formLoadPhoneCode = '';
            console.log('e', e);
        }
    }

    /**
     * Назад, к отправке номера телефона
     */
    @action backToSendPhone = () => {
        try {
            this.phoneCodeFlag = false;
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Отправка запроса на сброс пароля по email
     * @param values - пароль, email
     * @returns {Promise<unknown>}
     */
    @action resetPasswordByEmail = (values) => {
        try {
            this.formLoadFind = 'load';
            axios.post('/password/reset-password-by-email', values).then((response) => {
                this.formLoadFind = '';
                const {success} = response.data;
                if (success.status) {
                    this.serverErrorCreate = success.message;
                } else {
                    this.serverErrorCreate = success.message;
                }
            });
        } catch (e) {
            this.formLoadFind = '';
            console.log('e', e);
        }
    };
}
