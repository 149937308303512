import {observable, makeObservable, configure, action} from 'mobx';
import isEmpty from 'is-empty';

export default class ModalStore {
    // Модалки
    @observable selectSize = false;
    @observable conditionsPromotionOpen = false;
    @observable dialogCleanBasket = false;
    @observable dialogCleanItemBasket = false;
    @observable successRepeatOrderToggle = false;
    @observable qrCodeBonusAccountToggle = false;
    // Модалка узнать свой размер для SOUL
    @observable findYourSizeSoul = false;
    // Модалка Дополнительный выбор соул браслетов
    @observable additionalChoiceSoul = false;
    // Общая модалка с сообщением
    @observable serviceModalFlag = false;
    // Модальное окно, информация по цене
    @observable priceInfoModalFlag = false;
    // Общая модалка с сообщением об ошибке
    @observable serviceModalErrorFlag = false;
    // Модалка Дополнительные опции в корзине
    @observable basketAdditionalOptionsFlag = false;
    // Модалка Информация о доставке в детальной карточке продукта, на странице доставка и оплата
    @observable deliveryInformationFlag = false;
    // Статус получения информации о сроках доставки
    @observable deliveryInformationStatus = true;
    // Отправляемые данные для получения информации о сроках доставки
    @observable requestDeliveryData = {
        deliveryType: '', // 'brandShop', 'pvz'
        fieldType: 'city', // 'city', 'region'
        productData: [
            // {
            //     productId: null,
            //     productDataId: null,
            // }
        ]
    };
    @observable deliveryData = {
        title: '',
        data: []
    };
    // Сюда пишем сообщение от сервера
    @observable serviceMessage = '';
    // Сюда пишем сообщение об ошибке на сервере
    @observable serviceErrorMessage = '';
    // Модалка выбор адреса доставки в корзине
    @observable pvzDeliveryModal = false;
    // Сумма для расчета графика в модалке долями
    @observable dolyameSum = 0;
    // Тип модального окна, которое открыть
    @observable modalType = '';
    // Скидка бонусами
    @observable maxBonusesSubtract = 0;
    // Промокод для модалки с информацией
    @observable promoCodeValue = '';
    // Файловый менеджер в админке
    @observable flagModalFilesManager = false;
    // Окно сканирование бирок
    @observable flagModalScanner = false;
    // Окно фильтров для страницы поиска на мобильных устройствах
    @observable flagSearchFilters = false;
    // Окно покупки подарочных сертификатов
    @observable flagModalBuyGiftCertificate = false;
    // Окно добавления суммы для покупки подарочного сертификата
    @observable flagModalAddNominal = false;
    // Окно формы откликнуться на вакансию
    @observable flagModalJobsForm = false;
    // Название вакансии
    @observable jobsName = '';
    // Окно формы "Написать отзыв"
    @observable writeReviewModalFlag = false;
    // ID украшения
    @observable reviewProductId = '';
    // Модалка все фильтры
    @observable allFiltersFlag = false;
    // Модалка адреса доставки
    @observable dialogDeliveryAddresses = false;
    // Модалка добавить адрес доставки
    @observable dialogAddDeliveryAddress = false;
    // Модалка узнать цену
    @observable findOutPriceModalFlag = false;
    // Модалка узнать цену
    @observable creatingPreOrderModalFlag = false;
    // Модалка "Получить еще вращения"
    @observable additionalAttemptsWheelModalFlag = false;
    // Модалка "Получить еще вращения"
    @observable giftWheelModalFlag = false;
    // Порядковый номер выпавшего подарка
    @observable prizeIndex = null;
    // Модалка "Крутите колесо и получите свой гарантированный подарок!"
    @observable wheelOfFortuneFlag = false;

    prodId = null;
    prodDataId = null;
    count = null;

    @observable successRepeatOrderServerMsg = null;
    // Данные по pvz или brandShop для модалки адрес доставки в корзине
    @observable pvzOrBrandShopData = {
        pvz: [],
        brandShop: [],
    };
    // Текст сообщения
    @observable successMessage = '';
    // Источник, откуда была вызвана модальное окно
    @observable creatingPreOrderSource = '';

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
        this.rootStore = rootStore;
    }

    /**
     * Открытие окна "Дополнительные опции в корзине"
     */
    @action openBasketAdditionalOptions() {
        this.basketAdditionalOptionsFlag = true;
    }

    /**
     * Закрытие окна "Дополнительные опции в корзине"
     */
    @action closeBasketAdditionalOptions() {
        this.basketAdditionalOptionsFlag = false;
    }

    /**
     * Открытие окна "Дополнительный выбор соул браслетов"
     */
    @action openModalAdditionalChoiceSoul() {
        this.additionalChoiceSoul = true;
    }

    /**
     * Закрытие окна "Дополнительный выбор соул браслетов"
     */
    @action closeModalAdditionalChoiceSoul() {
        this.additionalChoiceSoul = false;
    }

    /**
     * Открытие окна "узнать свой размер для SOUL"
     */
    @action openModalFindYourSizeSoul() {
        this.findYourSizeSoul = true;
    }

    /**
     * Закрытие окна "узнать свой размер для SOUL"
     */
    @action closeModalFindYourSizeSoul() {
        this.findYourSizeSoul = false;
    }

    /**
     * Общая модалка с сообщением
     */
    @action openModalService = (message = '') => {
        this.serviceMessage = message;
        this.serviceModalFlag = true;
    }

    /**
     * Общая модалка с сообщением
     */
    @action closeModalService = () => {
        this.serviceMessage = '';
        this.serviceModalFlag = false;
    }

    /**
     * Модальное окно, информация по цене
     */
    @action openModalPriceInfo = (dolyameSum = 0, maxBonusesSubtract = 0, promoCodeValue = '') => {
        this.modalType = dolyameSum === 0 ? 'priceMinimum' : 'dolyame';
        this.dolyameSum = dolyameSum;
        this.maxBonusesSubtract = maxBonusesSubtract;
        this.promoCodeValue = promoCodeValue;
        this.priceInfoModalFlag = true;
    }

    /**
     * Модальное окно, информация по цене
     */
    @action closeModalPriceInfo = () => {
        this.priceInfoModalFlag = false;
        this.modalType = '';
    }

    /**
     * Модалка с выбором размера
     */
    @action openModalSelectSize = (idProd) => {
        this.selectSize = true;
        this.idProd = idProd;
    }

    /**
     * Модалка с выбором размера
     */
    @action closeModalSelectSize = () => {
        this.selectSize = false;
        this.rootStore.productDetailStore.dataSize = [];
        this.rootStore.productDetailStore.idData = '';
    }

    /**
     * Открытие модалки
     */
    @action openModalQrCodeBonusAccount() {
        this.qrCodeBonusAccountToggle = true;
    }

    /**
     * Закрытие модалки
     */
    @action closeModalQrCodeBonusAccount() {
        this.qrCodeBonusAccountToggle = false;
    }

    /**
     * Открытие модалки "информация о доставке"
     * @param deliveryType
     * @param productData
     * @param fieldType
     */
    @action openModalDeliveryInformation(deliveryType, productData = [], fieldType = 'city') {
        this.setRequestDeliveryData(deliveryType, productData, fieldType);
        this.getDeliveryInformation().then(() => this.deliveryInformationStatus = false);
        this.deliveryInformationFlag = true;
    }

    /**
     * Заполняем данные для получения информации по срокам доставки
     * @param deliveryType
     * @param productData
     * @param fieldType
     */
    @action setRequestDeliveryData = (deliveryType, productData = [], fieldType = 'city') => {
        this.requestDeliveryData = {
            deliveryType: deliveryType, // 'pvz', 'brandShop', 'courier'
            fieldType: fieldType, // 'city', 'region'
            productData: productData // [{productDataId: null}]
        }
    }

    // Запрос за информацией по срокам доставки
    @action getDeliveryInformation = async () => {
        try {
            const response = await axios.post('/delivery-info', this.requestDeliveryData);
            const {data} = response;
            const {success = {}} = data;
            const {deliveryData = {}} = success;

            this.deliveryData = deliveryData;
        } catch (error) {
            const {response} = error;
            console.log('error', error);
        }
    }

    /**
     * Закрытие модалки "информация о доставке"
     */
    @action closeModalDeliveryInformation() {
        this.deliveryInformationFlag = false;
        this.deliveryInformationStatus = true;
    }

    /**
     * Открытие модалки
     */
    @action openModalConditionsPromotion() {
        this.conditionsPromotionOpen = true;
    }

    /**
     * Закрытие модалки
     */
    @action closeModalConditionsPromotion() {
        this.conditionsPromotionOpen = false;
    }

    /**
     * Очистка корзины отрытие модалки
     */
    openModalCleanBasket = () => {
        this.dialogCleanBasket = true
    };

    /**
     * Очистка корзины закрытие модалки
     */
    closeModalCleanBasket = () => {
        this.dialogCleanBasket = false
    };

    /**
     * Удаление конкретного изделия отрытие модалки
     */
    openModalCleanItemBasket = (prodId = null, prodDataId = null, count = null) => {
        this.prodId = prodId;
        this.prodDataId = prodDataId;
        this.count = count;
        this.dialogCleanItemBasket = true
    };

    /**
     * Удаление конкретного изделия закрытие модалки
     */
    closeModalCleanItemBasket = async (flag = false) => {
        if (flag) {
            await this.rootStore.basketStore.deleteBasket(this.prodId, this.prodDataId, this.count, authCheck)
        }
        this.prodId = null;
        this.prodDataId = null;
        this.count = null;
        this.dialogCleanItemBasket = false
    };

    /**
     * Открытие модалки
     */
    openModalSuccessRepeatOrder = () => {
        this.successRepeatOrderToggle = true;
    }

    /**
     * Закрытие модалки
     */
    closeModalSuccessRepeatOrder = () => {
        this.successRepeatOrderToggle = false;
        this.successRepeatOrderServerMsg = null;
    }

    /**
     * Открываем файловый менеджер в админке
     */
    @action openModalFilesManager = () => {
        this.flagModalFilesManager = true;
    };

    /**
     * Закрываем файловый менеджер в админке
     */
    @action closeModalFilesManager = () => {
        this.flagModalFilesManager = false;
    };

    /**
     * Открываем окно для начала сканирования
     */
    @action openModalScanner = () => {
        this.flagModalScanner = true;
    };

    /**
     * Закрываем окно сканирования
     */
    @action closeModalScanner = () => {
        this.flagModalScanner = false;
    };

    /**
     * Открываем окно фильтров для страницы поиска на мобильных устройствах
     */
    @action openModalSearchFilters = () => {
        this.flagSearchFilters = true;
    };

    /**
     * Закрываем окно фильтров для страницы поиска на мобильных устройствах
     */
    @action closeModalSearchFilters = () => {
        this.flagSearchFilters = false;
    };

    /**
     * Открываем окно покупки подарочного сертификата
     */
    @action openModalBuyGiftCertificate = () => {
        this.flagModalBuyGiftCertificate = true;
    };

    /**
     * Закрываем окно покупки подарочного сертификата
     */
    @action closeModalBuyGiftCertificate = () => {
        this.flagModalBuyGiftCertificate = false;
    };

    /**
     * Открываем окно добавления суммы для покупки подарочного сертификата
     */
    @action openModalAddNominal = (e) => {
        e.preventDefault();
        this.flagModalAddNominal = true;
    };

    /**
     * Закрываем окно добавления суммы для покупки подарочного сертификата
     */
    @action closeModalAddNominal = () => {
        this.flagModalAddNominal = false;
    };

    /**
     * Открываем окно формы откликнуться на вакансию
     */
    @action openModalJobsForm(name) {
        // Запоминаем название вакансии
        this.jobsName = name;
        this.flagModalJobsForm = true;
    }

    /**
     * Закрываем окно формы откликнуться на вакансию
     */
    @action closeModalJobsForm() {
        this.flagModalJobsForm = false;
    }

    /**
     * Открываем окно формы откликнуться на вакансию
     */
    @action openModalWriteReview(product_id) {
        // Запоминаем id изделия
        this.reviewProductId = product_id;
        this.writeReviewModalFlag = true;
    }

    /**
     * Закрываем окно формы откликнуться на вакансию
     */
    @action closeModalWriteReview() {
        this.writeReviewModalFlag = false;
    }

    /**
     * Открыть модальное окно "Все фильтры"
     */
    @action openAllFilters = () => {
        this.allFiltersFlag = true;
    };

    /**
     * Закрыть модальное окно "Все фильтры"
     */
    @action closeAllFilters = () => {
        this.allFiltersFlag = false;
    };

    /**
     * Открыть модальное окно "Выбрать адрес доставки из списка"
     */
    @action openDialogDeliveryAddresses = () => {
        this.dialogDeliveryAddresses = true;
    };

    /**
     * Закрыть модальное окно "Выбрать адрес доставки из списка"
     */
    @action closeDialogDeliveryAddresses = () => {
        this.dialogDeliveryAddresses = false;
    };

    /**
     * Открыть модальное окно "Добавить адрес доставки"
     */
    @action openAddDeliveryAddressModal = (productData) => {
        this.getPvzAndShops(productData).then(() => this.deliveryInformationStatus = false);
        this.dialogAddDeliveryAddress = true;
    };

    // Запрос данных по pvz и brandShops для модалки в корзине
    @action getPvzAndShops = async (productData) => {
        try {
            const response = await axios.post('/get_pvz_and_shops', {productData: productData});
            const {data} = response;
            const {success = {}} = data;
            const {pvzData = [], brandShopData = []} = success;

            this.pvzOrBrandShopData.pvz = pvzData;
            this.pvzOrBrandShopData.brandShop = brandShopData;
        } catch (error) {
            console.log('error', error);
        }
    }

    /**
     * Закрыть модальное окно "Добавить адрес доставки"
     */
    @action closeAddDeliveryAddressModal = () => {
        this.dialogAddDeliveryAddress = false;
        this.deliveryInformationStatus = true;
    };

    /**
     * Открыть модальное окно "Узнать цену"
     */
    @action openFindOutPriceModal = (message = '') => {
        this.successMessage = message;
        this.findOutPriceModalFlag = true;
    }

    /**
     * Закрыть модальное окно "Узнать цену"
     */
    @action closeFindOutPriceModal = () => {
        this.findOutPriceModalFlag = false;
        this.successMessage = '';
    }

    /**
     * Открыть модальное окно "Создать предзаказ"
     * @param source
     */
    @action openCreatingPreOrderModal = (source = '') => {
        this.creatingPreOrderSource = source;
        this.creatingPreOrderModalFlag = true;
    }

    /**
     * Закрыть модальное окно "Создать предзаказ"
     */
    @action closeCreatingPreOrderModal = () => {
        this.creatingPreOrderModalFlag = false;
        this.creatingPreOrderSource = '';
    }

    /**
     * Открыть модальное окно "Получить еще вращения"
     */
    @action openAdditionalAttemptsWheelModal = () => {
        this.additionalAttemptsWheelModalFlag = true;
    }

    /**
     * Закрыть модальное окно "Получить еще вращения"
     */
    @action closeAdditionalAttemptsWheelModal = () => {
        this.additionalAttemptsWheelModalFlag = false;
    }

    /**
     * Открыть модальное окно "Выданный подарок"
     */
    @action openGiftWheelModal = () => {
        this.giftWheelModalFlag = true;
    }

    /**
     * Закрыть модальное окно "Выданный подарок"
     */
    @action closeGiftWheelModal = () => {
        this.giftWheelModalFlag = false;
    }

    /**
     * Открыть модальное окно "Выданный подарок"
     */
    @action openWheelOfFortuneModal = () => {
        this.wheelOfFortuneFlag = true;
    }

    /**
     * Закрыть модальное окно "Выданный подарок"
     */
    @action closeWheelOfFortuneModal = () => {
        localStorage.setItem('wheelOfFortune', '1');
        this.wheelOfFortuneFlag = false;
    }
}
